<template>
  <b-row align-h="center" v-if="dataLoading" class="mt-2">
    <b-spinner
      style="width: 4rem; height: 4rem"
      type="grow"
      variant="primary"
      label="Spinning"
    ></b-spinner>
  </b-row>
  <div v-else>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Periods</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group
              label="Period Name"
              invalid-feedback="Period name is required."
            >
              <b-form-input
                ref="pname"
                v-model.trim="pObj.period"
                placeholder="Enter period name"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="saveP()"
              :disabled="savingPeriod"
            >
              <b-spinner v-if="savingPeriod" small type="grow" />
              <feather-icon v-else :icon="buttonIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="allPeriods"
          :fields="pFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editP(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <!-- <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteP(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button> -->
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-modal
      id="modal-teacher"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Assign Teacher"
      size="lg"
    >
      <b-form-group class="">
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>

          <b-form-input v-model="searchQuery" placeholder="Search...">
          </b-form-input>
        </b-input-group>
      </b-form-group>
      <b-table
        class="mt-2 scroll-table"
        ref="selectableTable"
        :fields="teachersFields"
        :items="filters"
        :busy="dataLoading"
        show-empty
        responsive
        hover
        thead-class="d-none"
      >
        <!-- selectable
        select-mode="single"
        @row-selected="staffSelect($event[0])" -->
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <!-- <template #cell(name)="data">
          <div class="d-flex align-items-start">
            <div>
              <b-badge variant="light-primary" class="mb-50">
                {{ data.item.name }}
              </b-badge>
              <br />
              <b-badge variant="light-primary">
                F/N: {{ data.item.father_name }}
              </b-badge>
            </div>
            <div class="ml-2" v-if="data.item.occupied">
              <b-badge variant="light-success">
                {{ data.item.meta_class }}
              </b-badge>
           
              <feather-icon
                class="ml-50 cursor-pointer text-danger"
                icon="XIcon"
                size="18"
                v-b-tooltip.hover.right
                title="Unassign"
                @click.stop="unassign(data.item)"
              />
            </div>
          </div>
        </template> -->

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="55"
                :src="data.item.picture"
                variant="light-primary"
              />
            </template>

            <div class="d-flex">
              <div class="border-right pr-2">
                <b-link
                  class="font-weight-bold d-block text-nowrap h5"
                  style="margin-bottom: 4px"
                >
                  <b-badge variant="light-primary">
                    {{ data.item.name }}
                  </b-badge>
                </b-link>
                <b-link class="font-weight-bold d-block text-nowrap">
                  <b-badge variant="light-primary">
                    F/N: {{ data.item.father_name }}
                  </b-badge>
                </b-link>
              </div>
              <div class="pl-1">
                <div class="d-flex align-items-start">
                  <b-form-checkbox
                    v-model="data.item.forAll"
                    switch
                    class="mr-1 mb-1"
                    v-b-tooltip.hover.left
                    title="For all week"
                    @input="setForall(data.item)"
                  />
                  <b-badge
                    v-for="day in data.item.daysData"
                    :key="day.text"
                    class="mr-50 mb-50"
                    :variant="
                      day.occupied
                        ? 'light-danger'
                        : data.item.saveObj.dayList.includes(day.text)
                        ? 'light-success'
                        : 'light-secondary'
                    "
                    @click="!day.occupied && selectDays(day.text, data.item)"
                  >
                    {{ day.text.slice(0, 3) }}
                  </b-badge>
                </div>

                <div>
                  <b-badge
                    v-for="subs in data.item.subjects"
                    :key="subs.id"
                    class="mr-50 mb-50"
                    :variant="
                      subs.id == data.item.saveObj.subID
                        ? 'light-success'
                        : 'light-secondary'
                    "
                    @click="selectSubject(subs, data.item)"
                  >
                    {{ subs.name }}
                  </b-badge>
                </div>
              </div>
              <div class="ml-auto">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  :disabled="saving"
                >
                  <!-- @click="staffSelect(data.item)" -->
                  <b-spinner v-if="saving" small type="grow" />
                  <span v-else>Assign</span>
                </b-button>
              </div>
            </div>
          </b-media>
        </template>

        <template #cell(subject)="data">
          <div v-if="!data.item.occupied" class="d-flex align-items-center">
            <b-form-checkbox v-model="data.item.forAll" class="mr-1"
              >For all week</b-form-checkbox
            >
            <v-select
              ref="sub"
              style="flex: 1"
              v-model="data.item.selectedSub"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="data.item.subjects"
              label="name"
              :disabled="dataLoading"
              placeholder="Select subject"
            />
          </div>
        </template>
      </b-table>

      <!-- <b-row class="mt-2">
        <b-col style="display: flex; justify-content: end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="save()"
            :disabled="saving"
          >
            <b-spinner v-if="saving" small type="grow" />
            <span v-else>Assign</span>
          </b-button>
        </b-col>
      </b-row> -->
    </b-modal>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Assign Teacher</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Select Teacher"
              invalid-feedback="Teacher is required."
              ref="teacher"
            >
              <v-select
                ref="teacher"
                v-model="assignObj.tObj"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="teachers"
                label="name"
                :reduce="(val) => ({ id: val.id, name: val.name })"
                :disabled="dataLoading"
                placeholder="Select teacher"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="save()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Assign</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-row align-h="center" v-if="showSettings">
      <b-col xl="7" lg="8" md="12">
        <h3>
          <feather-icon
            icon="ArrowLeftIcon"
            size="22"
            class="cursor-pointer mr-50"
            @click="showSettings = false"
            v-b-tooltip.hover.top
            title="Back"
          />Period Settings
        </h3>
        <b-card class="mt-1">
          <b-row class="mb-1">
            <b-col cols="4"> <h5>Days:</h5> </b-col>
            <b-col cols="8" class="d-flex align-items-center">
              <h5 class="mr-1">Total Periods:</h5>
              <b-form-input
                class="w-50"
                placeholder="Enter no. of periods"
                type="number"
                v-model="tempPeriods"
                @keyup.enter="setPeriods()"
              />
            </b-col>
          </b-row>
          <b-row v-for="(item, ind) in alldays" :key="item.id" class="mb-1">
            <b-col cols="4">
              <h4 class="mb-0">
                <b-badge
                  :variant="
                    item.isHoliday ? 'light-secondary' : 'light-success'
                  "
                  @click="item.isHoliday = !item.isHoliday"
                  class="cursor-pointer w-100"
                >
                  {{ item.day }}
                </b-badge>
                <!-- <b-badge
                  :variant="
                    periodsData[ind].status
                      ? 'light-success'
                      : 'light-secondary'
                  "
                  @click="periodsData[ind].status = !periodsData[ind].status"
                  class="cursor-pointer w-100"
                >
                  {{ item.text }}
                </b-badge> -->
              </h4>
            </b-col>
            <b-col cols="8">
              <h4 class="mb-0">
                <b-badge
                  pill
                  v-for="count in totalPeriods"
                  :key="count"
                  :variant="
                    alldays[ind].totalPeriod == count
                      ? 'light-success'
                      : 'light-secondary'
                  "
                  @click="alldays[ind].totalPeriod = count"
                  class="mr-50 mb-50 cursor-pointer"
                >
                  {{ count }}
                </b-badge>
                <!-- <b-badge
                  pill
                  v-for="count in totalPeriods"
                  :key="count"
                  :variant="
                    periodsData[ind].periods == count
                      ? 'light-success'
                      : 'light-secondary'
                  "
                  @click="periodsData[ind].periods = count"
                  class="mr-50 mb-50 cursor-pointer"
                >
                  {{ count }}
                </b-badge> -->
              </h4>
            </b-col>
          </b-row>
        </b-card>
        <div class="d-flex align-items-center justify-content-between mb-50">
          <h3 class="mb-0">Timings</h3>
          <b-badge
            variant="primary"
            @click="openPeriod()"
            class="cursor-pointer"
          >
            Manage Periods
          </b-badge>
        </div>
        <b-card class="mt-1" v-for="(tcard, index) in timingData" :key="index">
          <b-row class="mb-1">
            <b-col class="d-flex justify-content-between flex-wrap">
              <h4 class="mb-0">
                <b-badge
                  v-for="(item, ind) in alldays"
                  :key="item.id"
                  :variant="
                    tcard.days.includes(item.day)
                      ? 'light-success'
                      : 'light-secondary'
                  "
                  class="cursor-pointer mr-50 mb-50"
                  @click="setTimingDay(item.day, index)"
                >
                  {{ item.day.slice(0, 3) }}
                </b-badge>
                <!-- <b-badge
                  v-for="(item, ind) in alldays"
                  :key="item.text"
                  :variant="
                    tcard.days.includes(item.value)
                      ? 'light-success'
                      : 'light-secondary'
                  "
                  class="cursor-pointer mr-50 mb-50"
                  @click="setTimingDay(item.value, index)"
                >
                  {{ item.text.slice(0, 3) }}
                </b-badge> -->
              </h4>
              <div class="d-flex">
                <b-form-checkbox
                  switch
                  v-model="tcard.autoAssign"
                  v-b-tooltip.hover.top
                  title="Auto assign time"
                />
                <b-button
                  @click="removeTimeCard(tcard.id, index)"
                  variant="outline-danger"
                  class="btn-icon rounded-circle ml-50"
                >
                  <feather-icon size="16" icon="XIcon" />
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row v-for="(rec, ind) in tcard.details" :key="ind" class="mb-1">
            <b-col md="3" sm="3" class="mb-md-0 mb-50">
              <h4 class="mb-0">
                <b-badge variant="light-success" class="w-100">
                  {{ rec.period }}
                </b-badge>
                <!-- <b-badge
                  :variant="rec.isBreak ? 'light-primary' : 'light-success'"
                  class="w-100"
                >
                  {{ rec.isBreak ? "Break" : `P${ind + 1}` }}
                </b-badge> -->
              </h4>
            </b-col>
            <b-col md="3" sm="3">
              <flat-pickr
                :config="config"
                v-model="rec.startsAt"
                placeholder="time from"
              />
            </b-col>
            <b-col md="3" sm="3">
              <flat-pickr
                :config="config"
                v-model="rec.endsAt"
                placeholder="time to"
                @on-close="setTime(rec, index, ind)"
              />
            </b-col>
            <!-- <b-col md="3" sm="3" v-if="rec.isBreak">
              <b-button
                variant="outline-primary"
                class="btn-icon mr-50"
                @click="moveUp(tcard.details, index, ind)"
              >
                <feather-icon icon="ArrowUpIcon" class="" />
              </b-button>
              <b-button
                variant="outline-primary"
                class="btn-icon"
                @click="moveDown(tcard.details, index, ind)"
              >
                <feather-icon icon="ArrowDownIcon" class="" />
              </b-button>
            </b-col> -->
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" class="text-center">
        <b-button
          @click="AddTimeCard()"
          variant="primary"
          class="btn-icon rounded-circle ml-auto mr-auto"
          :disabled="initLoading"
        >
          <b-spinner
            v-if="initLoading"
            style="width: 25px; height: 25px"
            small
          />
          <feather-icon v-else size="25" icon="PlusIcon" />
        </b-button>
      </b-col>
      <b-col md="5" class="mt-3">
        <b-button
          @click="saveSettings()"
          variant="success"
          class=""
          :disabled="savingSett"
          block
        >
          <b-spinner v-if="savingSett" small type="grow" />
          <span v-else>Save</span>
        </b-button>
      </b-col>
    </b-row>
    <div v-else>
      <b-row>
        <b-col xl="4" lg="5" md="6">
          <b-form-group
            label="Filter"
            invalid-feedback="Class is required."
            ref="fil_class"
          >
            <v-select
              multiple
              :closeOnSelect="false"
              ref="fil_class"
              v-model="filterClasses"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="classesOptions"
              label="name"
              :reduce="(val) => val.id"
              :disabled="dataLoading"
              placeholder="Select classes"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-md-2 mb-md-0 mb-1" xl="2" lg="3" md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
          >
            <span class="align-middle">Show</span>
          </b-button>
        </b-col>
        <b-col class="mt-md-2 mb-md-0 mb-1 text-right" xl="6" lg="4" md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="showSettings = true"
            class="btn-icon"
            v-b-tooltip.hover.top
            title="Settings"
          >
            <feather-icon icon="SettingsIcon" class="" />
            <!-- <span class="align-middle">Settings</span> -->
          </b-button>
        </b-col>
      </b-row>

      <b-row v-for="(card, cardInd) in myObj" :key="card" class="mt-1">
        <b-col md="12">
          <h4 v-if="card.class.clsID != 0 && card.class.secID != 0">
            {{ classesOptions.find((el) => el.id == card.class.clsID).name }} -
            {{
              classes
                .find((el) => el.cls.id == card.class.clsID)
                .sections.find((el) => el.id == card.class.secID).section
            }}
          </h4>
        </b-col>
        <b-col md="12">
          <b-card>
            <b-row align-h="start" class="">
              <b-col
                class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1"
                xl="3"
                lg="3"
                md="4"
              >
                <b-form-group
                  label="Select Class"
                  invalid-feedback="Class is required."
                  ref="class"
                >
                  <v-select
                    ref="class"
                    v-model="card.class.clsID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    :options="classesOptions"
                    label="name"
                    :reduce="(val) => val.id"
                    :disabled="dataLoading || disableList[cardInd]"
                    placeholder="Select class"
                    @input="FillSections(card, cardInd)"
                  />
                </b-form-group>
              </b-col>
              <b-col
                class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1"
                xl="3"
                lg="3"
                md="4"
              >
                <b-form-group
                  label="Select Section"
                  invalid-feedback="Section is required."
                  ref="sec"
                >
                  <!-- multiple
                :closeOnSelect="false" -->
                  <v-select
                    ref="sec"
                    v-model="card.class.secID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    :options="sections[cardInd]"
                    label="section"
                    :reduce="(val) => val.id"
                    :disabled="dataLoading || disableList[cardInd]"
                    placeholder="Select section"
                  />
                  <!-- @input="setSelected()" -->
                </b-form-group>
              </b-col>
              <b-col class="mt-sm-2 mt-1" xl="3" lg="3" md="2">
                <b-button
                  v-if="!disableList[cardInd]"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  variant="primary"
                  @click="setItems(cardInd)"
                >
                  <span class="align-middle">Create</span>
                </b-button>
              </b-col>
              <b-col class="mt-sm-2 mt-1 text-right" xl="3" lg="3" md="2">
                <b-button
                  @click="removeValue(card.class.id, cardInd)"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="18" icon="XIcon" />
                </b-button>
              </b-col>
              <!--
              <b-col class="mt-sm-2 mt-1" md="3">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  variant="primary"
                >
                  <span class="align-middle">Show</span>
                </b-button>
              </b-col>
              <b-col class="mt-sm-2 mt-1" md="3">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  variant="primary"
                  @click="visibility = true"
                >
                  <feather-icon icon="SettingsIcon" class="mr-50" />
                  <span class="align-middle">Settings</span>
                </b-button>
              </b-col> -->
            </b-row>

            <b-table
              v-if="card.items.length != 0"
              class="mt-1"
              :items="card.items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :busy="gridLoading"
              show-empty
              responsive
            >
              <!-- <template #empty="scope">
            <h3 class="mt-1 mb-1" style="text-align: center">
              No records found
            </h3>
          </template> -->
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>
              <template #cell(monday)="data">
                <template v-if="data.item.monday.slots.length > 0">
                  <div
                    v-for="(d, index) in data.item.monday.slots"
                    :key="d"
                    class="mb-1 mt-1"
                  >
                    <div v-if="d != null" class="d-flex">
                      <div>
                        <b-link
                          class="font-weight-bold d-block text-nowrap mb-50"
                        >
                          <b-badge variant="success">
                            <span>{{ d.name }}</span>
                          </b-badge>
                        </b-link>
                        <b-link class="font-weight-bold d-block text-nowrap">
                          <b-badge variant="light-info">
                            {{ d.subject }}
                          </b-badge>
                        </b-link>
                      </div>
                      <feather-icon
                        class="ml-1 cursor-pointer text-danger"
                        icon="XIcon"
                        size="20"
                        v-b-tooltip.hover.right
                        title="Unassign"
                        @click.stop="removeSlot('monday', index)"
                      />
                    </div>

                    <b-button
                      v-else
                      variant="outline-warning"
                      @click="AddOpen('monday', index)"
                    >
                      P {{ index + 1 }}
                    </b-button>
                  </div>
                </template>
                <h3 v-else>-</h3>
              </template>

              <template #cell(tuesday)="data">
                <template v-if="data.item.tuesday.slots.length > 0">
                  <div
                    v-for="(d, index) in data.item.tuesday.slots"
                    :key="d"
                    class="mb-1 mt-1"
                  >
                    <!-- <b-button variant="success" v-if="d != null">
                      {{ d.name }}
                    </b-button> -->
                    <div v-if="d != null" class="d-flex">
                      <div>
                        <b-link
                          class="font-weight-bold d-block text-nowrap mb-50"
                        >
                          <b-badge variant="success">
                            <span>{{ d.name }}</span>
                          </b-badge>
                        </b-link>
                        <b-link class="font-weight-bold d-block text-nowrap">
                          <b-badge variant="light-info">
                            {{ d.subject }}
                          </b-badge>
                        </b-link>
                      </div>
                      <feather-icon
                        class="ml-1 cursor-pointer text-danger"
                        icon="XIcon"
                        size="20"
                        title="Unassign"
                        @click.stop="removeSlot('monday', index)"
                      />
                    </div>

                    <b-button
                      v-else
                      variant="outline-warning"
                      @click="AddOpen('tuesday', index)"
                    >
                      P {{ index + 1 }}
                    </b-button>
                  </div>
                </template>
                <h3 v-else>-</h3>
              </template>

              <template #cell(wednesday)="data">
                <template v-if="data.item.wednesday.slots.length > 0">
                  <div
                    v-for="(d, index) in data.item.wednesday.slots"
                    :key="d"
                    class="mb-1 mt-1"
                  >
                    <div v-if="d != null" class="d-flex">
                      <div>
                        <b-link
                          class="font-weight-bold d-block text-nowrap mb-50"
                        >
                          <b-badge variant="success">
                            <span>{{ d.name }}</span>
                          </b-badge>
                        </b-link>
                        <b-link class="font-weight-bold d-block text-nowrap">
                          <b-badge variant="light-info">
                            {{ d.subject }}
                          </b-badge>
                        </b-link>
                      </div>
                      <feather-icon
                        class="ml-1 cursor-pointer text-danger"
                        icon="XIcon"
                        size="20"
                        title="Unassign"
                        @click.stop="removeSlot('monday', index)"
                      />
                    </div>
                    <b-button
                      v-else
                      variant="outline-warning"
                      @click="AddOpen('wednesday', index)"
                    >
                      P {{ index + 1 }}
                    </b-button>
                  </div>
                </template>
                <h3 v-else>-</h3>
              </template>

              <template #cell(thursday)="data">
                <template v-if="data.item.thursday.slots.length > 0">
                  <div
                    v-for="(d, index) in data.item.thursday.slots"
                    :key="d"
                    class="mb-1 mt-1"
                  >
                    <div v-if="d != null" class="d-flex">
                      <div>
                        <b-link
                          class="font-weight-bold d-block text-nowrap mb-50"
                        >
                          <b-badge variant="success">
                            <span>{{ d.name }}</span>
                          </b-badge>
                        </b-link>
                        <b-link class="font-weight-bold d-block text-nowrap">
                          <b-badge variant="light-info">
                            {{ d.subject }}
                          </b-badge>
                        </b-link>
                      </div>
                      <feather-icon
                        class="ml-1 cursor-pointer text-danger"
                        icon="XIcon"
                        size="20"
                        title="Unassign"
                        @click.stop="removeSlot('monday', index)"
                      />
                    </div>
                    <b-button
                      v-else
                      variant="outline-warning"
                      @click="AddOpen('thursday', index)"
                    >
                      P {{ index + 1 }}
                    </b-button>
                  </div>
                </template>
                <h3 v-else>-</h3>
              </template>

              <template #cell(friday)="data">
                <template v-if="data.item.friday.slots.length > 0">
                  <div
                    v-for="(d, index) in data.item.friday.slots"
                    :key="d"
                    class="mb-1 mt-1"
                  >
                    <div v-if="d != null" class="d-flex">
                      <div>
                        <b-link
                          class="font-weight-bold d-block text-nowrap mb-50"
                        >
                          <b-badge variant="success">
                            <span>{{ d.name }}</span>
                          </b-badge>
                        </b-link>
                        <b-link class="font-weight-bold d-block text-nowrap">
                          <b-badge variant="light-info">
                            {{ d.subject }}
                          </b-badge>
                        </b-link>
                      </div>
                      <feather-icon
                        class="ml-1 cursor-pointer text-danger"
                        icon="XIcon"
                        size="20"
                        title="Unassign"
                        @click.stop="removeSlot('monday', index)"
                      />
                    </div>
                    <b-button
                      v-else
                      variant="outline-warning"
                      @click="AddOpen('friday', index)"
                    >
                      P {{ index + 1 }}
                    </b-button>
                  </div>
                </template>
                <h3 v-else>-</h3>
              </template>

              <template #cell(saturday)="data">
                <template v-if="data.item.saturday.slots.length > 0">
                  <div
                    v-for="(d, index) in data.item.saturday.slots"
                    :key="d"
                    class="mb-1 mt-1"
                  >
                    <div v-if="d != null" class="d-flex">
                      <div>
                        <b-link
                          class="font-weight-bold d-block text-nowrap mb-50"
                        >
                          <b-badge variant="success">
                            <span>{{ d.name }}</span>
                          </b-badge>
                        </b-link>
                        <b-link class="font-weight-bold d-block text-nowrap">
                          <b-badge variant="light-info">
                            {{ d.subject }}
                          </b-badge>
                        </b-link>
                      </div>
                      <feather-icon
                        class="ml-1 cursor-pointer text-danger"
                        icon="XIcon"
                        size="20"
                        title="Unassign"
                        @click.stop="removeSlot('monday', index)"
                      />
                    </div>
                    <b-button
                      v-else
                      variant="outline-warning"
                      @click="AddOpen('saturday', index)"
                    >
                      P {{ index + 1 }}
                    </b-button>
                  </div>
                </template>
                <h3 v-else>-</h3>
              </template>

              <template #cell(sunday)="data">
                <template v-if="data.item.sunday.slots.length > 0">
                  <div
                    v-for="(d, index) in data.item.sunday.slots"
                    :key="d"
                    class="mb-1 mt-1"
                  >
                    <div v-if="d != null" class="d-flex">
                      <div>
                        <b-link
                          class="font-weight-bold d-block text-nowrap mb-50"
                        >
                          <b-badge variant="success">
                            <span>{{ d.name }}</span>
                          </b-badge>
                        </b-link>
                        <b-link class="font-weight-bold d-block text-nowrap">
                          <b-badge variant="light-info">
                            {{ d.subject }}
                          </b-badge>
                        </b-link>
                      </div>
                      <feather-icon
                        class="ml-1 cursor-pointer text-danger"
                        icon="XIcon"
                        size="20"
                        title="Unassign"
                        @click.stop="removeSlot('monday', index)"
                      />
                    </div>
                    <b-button
                      v-else
                      variant="outline-warning"
                      @click="AddOpen('sunday', index)"
                    >
                      P {{ index + 1 }}
                    </b-button>
                  </div>
                </template>
                <h3 v-else>-</h3>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col class="text-center" v-if="cardInd == myObj.length - 1">
          <b-button
            @click="AddValue(card.class)"
            variant="primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon size="25" icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,

    flatPickr,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BMediaAside,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    this.LoadInfo();
    // this.setItems();
    // ===========
    this.LoadData();
    this.loadPeriods();

    // this.loadDays();
  },
  computed: {
    filters: function () {
      return this.teachers.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      request: false,
      saving: false,
      showSettings: false,
      alldays: [
        { text: "Monday", value: "monday" },
        { text: "Tuesday", value: "tuesday" },
        { text: "Wednesday", value: "wednesday" },
        { text: "Thursday", value: "thursday" },
        { text: "Friday", value: "friday" },
        { text: "Saturday", value: "saturday" },
        { text: "Sunday", value: "sunday" },
      ],
      sidebarTitle: "Add Working Day",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      gridLoading: false,
      visibility: false,
      visibility2: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      index: null,
      filterClasses: [],
      classes: [],
      classesOptions: [],
      sections: [],
      teachers: [],
      teachersFields: [
        { label: "name", key: "name", tdClass: "pl-50 pr-50" },
        // { label: "subject", key: "subject" },
      ],
      tempPeriods: 7,
      totalPeriods: 7,
      periodsData: [
        { id: 1, day: "monday", status: true, periods: 7 },
        { id: 2, day: "tuesday", status: true, periods: 7 },
        { id: 3, day: "wednesday", status: true, periods: 7 },
        { id: 4, day: "thursday", status: true, periods: 7 },
        { id: 5, day: "friday", status: true, periods: 5 },
        { id: 6, day: "saturday", status: false, periods: 7 },
        { id: 7, day: "sunday", status: false, periods: 7 },
      ],
      timingData: [
        // {
        //   id: 0,
        //   autoAssign: false,
        //   days: ["monday", "tuesday", "wednesday", "thursday"],
        //   details: [
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: true, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //   ],
        // },
        // {
        //   id: 0,
        //   autoAssign: false,
        //   days: ["friday"],
        //   details: [
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: true, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //   ],
        // },
      ],
      config: {
        enableTime: true,
        noCalendar: true,
        altFormat: "h:i K",
        altInput: true,
        dateFormat: "Y-m-d\\TH:i:S",
        defaultHour: 8,
      },
      fields: [
        { label: "Monday", key: "monday" },
        { label: "Tuesday", key: "tuesday" },
        { label: "Wednesday", key: "wednesday" },
        { label: "Thursday", key: "thursday" },
        { label: "Friday", key: "friday" },
        { label: "Saturday", key: "saturday" },
      ],
      items: [
        // {
        //   monday: {
        //     id: 1,
        //     slots: [
        //       { subject: "mathematics", subID: 5, tID: 1, name: "Ali" },
        //       { subject: "physics", subID: 2, tID: 1, name: "Ali" },
        //       null,
        //       null,
        //       null,
        //     ],
        //   },
        //   tuesday: {
        //     id: 2,
        //     slots: [
        //       null,
        //       null,
        //       { subject: "english", subID: 3, tID: 3, name: "Ahmed" },
        //       null,
        //       null,
        //     ],
        //   },
        //   wednesday: {
        //     id: 3,
        //     slots: [],
        //   },
        //   thursday: {
        //     id: 4,
        //     slots: [],
        //   },
        //   friday: {
        //     id: 5,
        //     slots: [],
        //   },
        //   saturday: {
        //     id: 5,
        //     slots: [],
        //   },
        //   sunday: {
        //     id: 5,
        //     slots: [],
        //   },
        // },
      ],

      workingDays: [],
      secText: "Section",
      classText: "Class",
      teaText: "Teacher",
      selected: null,
      rangeDate: null,
      myObj: [
        {
          class: {
            id: 0,
            clsID: 0,
            secID: 0,
            campusID: this.$store.state.userData.cId,
          },
          items: [],
        },
      ],
      assignObj: {
        tID: 0,
        name: "",
        subID: 0,
        subject: "",
        dayList: [],
        campusID: this.$store.state.userData.cId,
      },
      disableList: [false],
      timeout: 10000,
      slotInfo: {},
      selectedIDs: [],
      allPeriods: [],
      pFields: [
        { label: "period", key: "period" },
        { label: "actions", key: "actions" },
      ],
      savingPeriod: false,
      pObj: {
        id: 0,
        period: "",
        campusID: this.$store.state.userData.cId,
      },
      initLoading: false,
      dayList: [],
      selectedDays: [],
      savingSett: false,
    };
  },
  validations: {
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async loadPeriods() {
      var obj = {
        url:
          this.$store.state.domain +
          "timetable_period?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allPeriods = await this.get(obj);
      // console.log(this.allPeriods);
    },
    openPeriod() {
      this.buttonIcon = "PlusIcon";
      this.visibility = true;
      this.pObj = {
        id: 0,
        period: "",
        campusID: this.$store.state.userData.cId,
      };
      var elem = this.$refs["pname"];
      elem.state = undefined;
    },
    checkPeriod() {
      var elem = this.$refs["pname"];
      if (this.pObj.period == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveP() {
      if (this.checkPeriod() == true) {
        this.savingPeriod = true;
        if (this.pObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "timetable_period?db=" +
              this.$store.state.userData.db,
            body: this.pObj,
            message: "Period added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "timetable_period/" +
              this.pObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Period updated successfully.",
            context: this,
            body: this.pObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.loadPeriods();
          this.pObj = {
            id: 0,
            period: "",
            campusID: this.$store.state.userData.cId,
          };
          var elem = this.$refs["pname"];
          elem.state = undefined;
        }
        this.savingPeriod = false;
      }
    },
    async editP(item) {
      this.pObj = { ...item };
      this.buttonIcon = "Edit2Icon";
      var elem = this.$refs["pname"];
      elem.state = undefined;
      // console.log(this.pObj);
    },

    async deleteP(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "timetable_period/" +
            id +
            "?db=" +
            this.$store.state.userData.db,

          message: "Period deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadPeriods();
      }
    },

    AddOpen(day, ind) {
      this.slotInfo = {
        day: day,
        index: ind,
      };
      // console.log(this.slotInfo);

      this.assignObj = {
        tID: 0,
        name: "",
        subID: 0,
        subject: "",
        dayList: [],
        campusID: this.$store.state.userData.cId,
      };

      // this.visibility2 = true;
      this.$bvModal.show("modal-teacher");
      var elem = this.$refs["teacher"];
      elem.state = undefined;
    },

    async removeSlot(day, ind) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Unassign it!",
      });
      if (result.isConfirmed) {
        let current = this.items[0];
        current[day].slots[ind] = null;
        this.items = [current];
      }
    },

    async unassign(item) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Unassign it!",
      });
      if (result.isConfirmed) {
        console.log(item.assignID);
        item.occupied = false;
      }
    },

    selectDays(day, item) {
      if (item.saveObj.dayList.includes(day)) {
        item.saveObj.dayList = item.saveObj.dayList.filter((el) => el != day);
      } else {
        item.saveObj.dayList.push(day);
      }
      // console.log(item.saveObj);
    },

    selectSubject(sub, item) {
      item.saveObj.subID = sub.id;
      item.saveObj.subject = sub.name;
    },

    setForall(item) {
      if (item.forAll) {
        let data = item.daysData.filter((el) => !el.occupied);
        item.saveObj.dayList = data.map((el) => el.text);
      }
      // console.log(item.saveObj.dayList);
    },

    AddValue(item) {
      if (item.clsID != 0 && item.secID != 0) {
        this.myObj.push({
          class: {
            id: 0,
            clsID: 0,
            secID: 0,
            campusID: this.$store.state.userData.cId,
          },
          items: [],
        });
        this.disableList.push(false);
      }
    },
    removeValue(id, ind) {
      // console.log(id, ind);
      if (id == 0 && this.myObj.length == 1) {
        // console.log("len");
        this.myObj = [
          {
            class: {
              id: 0,
              clsID: 0,
              secID: 0,
              campusID: this.$store.state.userData.cId,
            },
            items: [],
          },
        ];
        this.disableList = [false];
        this.sections = [];
      } else if (id == 0) {
        this.myObj.splice(ind, 1);
        this.disableList.splice(ind, 1);
        this.sections.splice(ind, 1);
      } else {
        console.log("by api");
      }
    },

    staffSelect(row) {
      // console.log(row);
      if (row) {
        if (row.occupied) {
          this.$bvToast.toast("Already assigned to " + row.meta_class, {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
          this.$refs.selectableTable.clearSelected();
        } else if (row.selectedSub) {
          this.assignObj.tID = row.id;
          this.assignObj.name = row.name;
          this.assignObj.subID = row.selectedSub.id;
          this.assignObj.subject = row.selectedSub.name;

          // console.log(this.assignObj);

          // if (row.forAll) {
          // }
          let current = this.items[0];
          current[this.slotInfo.day].slots[this.slotInfo.index] =
            this.assignObj;
          this.items = [current];

          this.$bvModal.hide("modal-teacher");
        } else {
          this.$bvToast.toast("Please select the subject", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
          this.$refs.selectableTable.clearSelected();
        }
      }
    },

    save() {
      if (this.assignObj.tID == 0) {
        return this.$bvToast.toast("Please select the teacher!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        let current = this.items[0];
        current[this.slotInfo.day].slots[this.slotInfo.index] = this.assignObj;
        this.items = [current];
      }
    },

    Timetable() {
      console.log(this.myObj);
    },
    hideSideBar() {
      this.visibility = false;
    },
    FillSections(item, ind) {
      item.class.secID = 0;
      this.sections[ind] = this.classes.find(
        (el) => el.cls.id == item.class.clsID
      ).sections;
      console.log(this.sections);
      // this.sections.unshift({ id: 0, name: "All Sections"})
    },
    setSelected() {
      this.selectedIDs = this.myObj.map((el) => {
        return { cls: el.class.clsID, sec: el.class.secID };
      });
      console.log(this.selectedIDs);
    },

    moveFocus(ind) {
      let next = document.querySelector(`#period${ind + 1}`);
      if (next) {
        next.focus();
        next.select();
      }
    },

    setPeriods() {
      var re = /^[0-9]+$/;
      let x = parseInt(this.tempPeriods);
      if (!re.test(this.tempPeriods) || isNaN(x) || x <= 0) {
        this.$bvToast.toast("Please enter valid number", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else this.totalPeriods = parseInt(this.tempPeriods);
    },
    setTimingDay(val, ind) {
      // console.log(val, ind);
      if (this.timingData[ind].days.includes(val)) {
        this.timingData[ind].days = this.timingData[ind].days.filter(
          (el) => el != val
        );
        this.selectedDays = this.selectedDays.filter((el) => el != val);
      } else if (!this.selectedDays.includes(val)) {
        this.timingData[ind].days.push(val);
        this.selectedDays.push(val);
      }
      // else this.timingData[ind].days.push(val);
      // console.log(this.timingData[ind].days);
    },
    setTime(curr, tIndex, ind) {
      if (curr.startsAt && curr.endsAt && this.timingData[tIndex].autoAssign) {
        let tf = new Date(curr.startsAt);
        let tt = new Date(curr.endsAt);
        let items = this.timingData[tIndex].details;
        if (tf < tt && ind != items.length - 1) {
          let diff = tt - tf;
          // adjust for break slot
          if (curr.isBreak) {
            let data = items.find(
              (el) => !el.isBreak && el.startsAt && el.endsAt
            );
            if (data) diff = new Date(data.endsAt) - new Date(data.startsAt);
          }
          let lastTo = curr.endsAt;
          let newTo = new Date(tt.getTime() + diff);

          // updating next slots
          items.slice(ind + 1).forEach((el) => {
            el.startsAt = lastTo;
            el.endsAt = newTo;
            lastTo = newTo;
            newTo = new Date(newTo.getTime() + diff);
          });
          // console.log(items);
          this.timingData[tIndex].details = [...items];
        }
      }
    },
    moveUp(items, tIndex, ind) {
      if (ind != 0) {
        let y = items[ind - 1];
        items[ind - 1] = items[ind];
        items[ind] = y;
        this.timingData[tIndex].details = [...items];
      }
    },
    moveDown(items, tIndex, ind) {
      if (ind != items.length - 1) {
        let y = items[ind + 1];
        items[ind + 1] = items[ind];
        items[ind] = y;
        this.timingData[tIndex].details = [...items];
      }
    },
    async AddTimeCard() {
      if (this.selectedDays.length == 7) {
        this.$bvToast.toast("You have defined the periods for all days.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        this.initLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "timetable_latest/IniTimings?db=" +
            this.$store.state.userData.db +
            "&cId=" +
            this.$store.state.userData.cId,
          token: this.$store.state.userData.token,
          subjects: true,
          context: this,
        };
        let res = await this.post(obj);
        // console.log(res);

        let set2 = new Set(this.selectedDays);
        this.timingData.push({
          id: 0,
          autoAssign: true,
          days: this.dayList.filter((value) => !set2.has(value)),
          details: res.periodData,
        });
        this.selectedDays = this.timingData.reduce((acc, el) => {
          acc = [...el.days, ...acc];
          return acc;
        }, []);
        this.initLoading = false;
        // this.timingData.push({
        //   id: 0,
        //   autoAssign: false,
        //   days: [],
        //   details: [
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: true, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //     { isBreak: false, timeFrom: null, timeTo: null },
        //   ],
        // });
      }
    },
    removeTimeCard(id, ind) {
      // if (id == 0 && this.timingData.length == 1) {
      //   console.log("len");
      //   this.timingData = [
      //     {
      //       id: 0,
      //       autoAssign: false,
      //       days: [],
      //       details: [
      //         { isBreak: false, timeFrom: null, timeTo: null },
      //         { isBreak: false, timeFrom: null, timeTo: null },
      //         { isBreak: false, timeFrom: null, timeTo: null },
      //         { isBreak: true, timeFrom: null, timeTo: null },
      //         { isBreak: false, timeFrom: null, timeTo: null },
      //         { isBreak: false, timeFrom: null, timeTo: null },
      //         { isBreak: false, timeFrom: null, timeTo: null },
      //       ],
      //     },
      //   ];
      // }
      if (id == 0) {
        this.timingData.splice(ind, 1);
        this.selectedDays = this.timingData.reduce((acc, el) => {
          acc = [...el.days, ...acc];
          return acc;
        }, []);
      } else {
        console.log("by api");
      }
    },

    async saveSettings() {
      const sortDays = (a, b) => {
        a = this.dayList.indexOf(a);
        b = this.dayList.indexOf(b);
        return a < b ? 0 : 1;
      };
      let data = this.timingData.map((el) => {
        return {
          periodData: [...el.details],
          days: el.days.sort(sortDays).join(","),
        };
      });
      console.log(data);
      // this.savingSett = true;
    },

    checkValues() {
      var re = /^[0-9]+$/;
      let check = true;
      this.periodsData.forEach((el) => {
        if (el.status) {
          let x = parseInt(el.periods);
          if (!re.test(el.periods) || isNaN(x) || x < 0) {
            check = false;
          } else el.periods = parseInt(el.periods);
        }
      });
      return check;
    },
    setItems(ind) {
      if (
        this.myObj[ind].class.clsID != 0 &&
        this.myObj[ind].class.secID != 0
      ) {
        let filtered = this.periodsData.filter((el) => el.status);

        this.fields = filtered.map((el) => ({
          label: el.day,
          key: el.day,
          tdClass: "align-top",
        }));
        let obj = filtered.reduce((acc, el) => {
          acc[el.day] = {
            id: el.id,
            slots: Array(el.periods).fill(null),
          };
          return acc;
        }, {});
        this.myObj[ind].items = [obj];
        this.disableList[ind] = true;
        // this.items = [obj];
        // console.log(this.items);
      } else {
        this.$bvToast.toast("Please select class and section", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      }
    },

    async Add() {
      if (this.checkValues() == false) {
        return this.$bvToast.toast("Please enter the correct details.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.setItems();
        this.visibility = false;
      }
    },

    async LoadData() {
      this.gridLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "timetable_latest/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      console.log(res);
      this.alldays = res.days;
      this.dayList = this.alldays.map((el) => el.day);
      this.tempPeriods = res.totalPeriod > 0 ? res.totalPeriod : 7;
      this.totalPeriods = this.tempPeriods;
      this.gridLoading = false;
    },
    async LoadInfo() {
      this.dataLoading = true;
      // classes dropdown
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = [];
      this.classes.forEach((el) => {
        this.classesOptions.push(el.cls);
      });
      // console.log("classes", this.classes);

      // teachers dropdown
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "staff/filter?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dptID=0&status=present",
        token: this.$store.state.userData.token,
      });
      let res = myitem;
      let subjects = [
        { id: 1, name: "Maths" },
        { id: 2, name: "English" },
      ];
      let daysData = [
        { text: "Monday", occupied: false },
        { text: "Tuesday", occupied: false },
        { text: "Wednesday", occupied: true },
        { text: "Thursday", occupied: false },
        { text: "Friday", occupied: false },
        { text: "Saturday", occupied: false },
        { text: "Sunday", occupied: true },
      ];
      this.teachers = res.map((el, ind) => {
        // if (ind % 2 == 0)
        //   return {
        //     ...el,
        //     occupied: true,
        //     assignID: ind,
        //     meta_class: "VI - A",
        //     meta_subject: "English",
        //     subjects: subjects,
        //   };
        // else return { ...el, subjects: subjects };
        return {
          ...el,
          occupied: true,
          assignID: ind,
          meta_class: "VI - A",
          meta_subject: "English",
          subjects: subjects,
          daysData: daysData,
          forAll: false,
          saveObj: {
            tID: el.id,
            name: el.name,
            subID: 0,
            subject: "",
            dayList: [],
            campusID: this.$store.state.userData.cId,
          },
        };
      });
      // console.log(this.teachers);
      this.dataLoading = false;
    },

    async loadDays() {
      var obj = {
        url:
          this.$store.state.domain +
          "timetable_days?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      console.log(res);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style lang="css" scoped>
.scroll-table {
  height: 400px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
